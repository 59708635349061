import React from 'react';

const cup_types = [
	{
		label: `I'll bring my own`,
		value: 'bring_own'
	},
	{
		label: `Yeti`,
		value: 'yeti'
	},
	{
		label: `Rtic`,
		value: 'rtic'
	},
	{
		label: `S'well`,
		value: 'swell'
	}
]

const TextButton = (props) => (
	<button disabled={ props.disabled } className={'bg-near-black white bg-animate bn f6 pa2 ph3 br1 ttu tracked' + (props.disabled ? ' o-40 cursor-not-allowed ' : ' hover-bg-black-10 pointer ')} onClick={ props.onClick }>
		<span className='dib v-mid'>{ props.title }</span>
	</button>
)

const ContactForm = (props) => (
	<form onSubmit={ props.onSubmit }>
		<div className=''>
			<FormInput autocomplete='name' name='name' label="Your Full Name" value={ props.data.name } onChange={ props.onChange } />
	    </div>
	    <div className='cf'>
		    <div className='w-100 w-50-ns fl-ns pr4-ns'>
				<FormInput autocomplete='email' name='email' label="Email Address" value={ props.data.email } onChange={ props.onChange } />
		    </div>
		    <div className='w-100 w-50-ns fl-ns'>
		    	<FormInput autocomplete='tel' name='phone' label="Phone Number (Optional)" value={ props.data.phone } onChange={ props.onChange } />
		    </div>
		</div>
	    <div className=''>
	    	<FormTextArea name='message' label="Message" value={ props.data.message } onChange={ props.onChange } minHeight={ 100 } />
	    </div>
	</form>
)

const CustomWorkForm = (props) => (
	<form className='relative' onSubmit={ props.onSubmit }>
		<FormInput autocomplete='name' name='name' label="Your Full Name" value={ props.data.name } onChange={ props.onChange } />
		<div className='cf nh3-ns'>
			<div className='w-100 w-50-ns fl ph3-ns'><FormInput autocomplete='email' name='email' label="Email Address" value={ props.data.email } onChange={ props.onChange } /></div>
			<div className='w-100 w-50-ns fl ph3-ns'><FormInput autocomplete='tel' name='phone' label="Phone Number" value={ props.data.phone } onChange={ props.onChange } /></div>
		</div>
		<FormInput autocomplete='name' name='company' label="Company Name" value={ props.data.company } onChange={ props.onChange } />
		<div className='cf nh3-ns'>
			<div className='w-100 w-50-ns fl ph3-ns'><FormSelect name='cup_type' label="Desired Cup" items={ cup_types } value={ props.data.cup_type } onChange={ props.onChange } /></div>
			<div className='w-100 w-50-ns fl ph3-ns'><FormInput name='quantity' label="Desired Quantity" value={ props.data.quantity } onChange={ props.onChange } /></div>
		</div>
		<FormTextArea name='info' label='Additional Info' value={ props.data.info } onChange={ props.onChange } desc={ "Additional questions? Want to describe the project? Put it all here." } minHeight={ 200 } />
	</form>
)

const FormLabel = (props) => (
	<span className={'db ttu tracked black-50 f6 pb2 bg-transparent roboto fw4'}>{ props.title }</span>
)

const FormInput = (props) => (
	<label className='db mb3 pb2'>
		<FormLabel title={ props.label } />
		<input disabled={ props.disabled } pattern={ props.pattern } autoComplete={ props.autocomplete || null } required name={ props.name } className={'db black f5 w-100 ' + ( props.disabled ? 'bg-transparent cursor-disabled pv2' : ' ba b--near-black pa2 ' )} type="text" value={ props.value } onChange={ props.onChange } />
		{ props.desc &&
			<p className='dib v-mid ma0 pa0 pt1 f7 black-50'>{ props.desc }</p>
		}
	</label>
)

const FormSelect = (props) => (
	<div>
		<FormLabel title={ props.label } />
		<label className={'db mb4 custom-select ' + (props.disabled ? ' custom-select-disabled ' : ' ')}>
			<select disabled={ props.disabled } autoComplete={ props.autocomplete || null } required name={ props.name } className={'db black ba b--near-black br0 pb2 f5 w-100 ' + ( props.disabled ? 'bg-transparent cursor-disabled pv2' : 'pa2 ' )} value={ props.value } onChange={ props.onChange }>
				{ props.items.map( item =>
					<option key={ item.value } value={ item.value }>{ item.label }</option>
				)}
			</select>
		</label>
	</div>
)

const FormTextArea = (props) => (
	<label className='db mb3 pb2'>
		<FormLabel title={ props.label } />
		<textarea className='db ba b--near-black near-black pa2 f5 w-100' name={ props.name } value={ props.value } onChange={ props.onChange } style={{ minHeight: props.minHeight }}/>
		{ props.desc &&
			<p className='dib v-mid ma0 pa0 pt1 f7 black-50'>{ props.desc }</p>
		}
	</label>
)

export { TextButton, FormLabel, FormInput, FormSelect, FormTextArea, CustomWorkForm, ContactForm };