import React from 'react'
import api from '../lib/api'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Header from '../components/header'
import { ContactForm, TextButton } from '../components/forms'


class ContactPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			phone: '',
			message: '',
			sent: false
		}
		this.emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
	}
	updateField = (event) => {
		const new_value = event.target.value;
		this.setState({ [event.target.name]: new_value });
	}
	emailValid = () => {
		return this.emailRegex.test(this.state.email);
	}
	valid = () => {
		const s = this.state;
		return s.message.length > 2 && s.name.length > 2 && this.emailValid();
	}
	submit = async () => {
		if(this.valid()) {
			try {
				let req = { ...this.state };
				const response = await api.contact({ subject: 'Contact Form Submission', request: req });
				if(response.error) {
					// messages.show('CONTACT_FAILED');
					return;
				}
				this.setState({ sent: true });
				// messages.show('CONTACT_SENT');
			} catch(e) {
				// messages.show('CONTACT_FAILED');
			}
		}
	}
	render() {
		return (
			  <Layout>
			  	<div className='cf'>
			  		<div className='w-100 w-80-ns center'>
			  			<Header />
			  			<div className='mt4-ns pv4 mh4 mh0-ns'>
			  				<div>
			  					<h2 className='roboto f3 fw4 near-black'>Get in touch.</h2>
			  					<p className='lh-copy roboto'>We respond to all inquiries within 1 business day. If you need a more immediate response, give us a call at 832.919.7610.</p>
			  				</div>
			  			</div>
			  			<div className='cf pv4'>
			  				<div className='w-100 w-50-l fl pr5-l ph4 pl0-ns mb5'>
			  					<ContactForm data={ this.state } onChange={ this.updateField } onSubmit={ this.submit } />
			  					<div className='tr mt3'>
			  						{ !this.state.sent &&
			  							<TextButton disabled={ !this.valid() } onClick={ this.submit } title='Send' />
			  						}
			  						{ this.state.sent &&
			  							<p className='near-black roboto lh-copy anim-in-slow ba b--black-10 dib pa2 ph3'>Thanks! Message sent.</p>
			  						}
			  					</div>
			  				</div>
			  				<div className='w-100 w-50-l fl ph4 ph0-ns mb5-l'>
			  					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3463.09908022655!2d-95.37855898451984!3d29.774776438215287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640b8b4f1ae0d93%3A0xbc58fa666f4809c8!2s1907+Sabine+St+%23144%2C+Houston%2C+TX+77007!5e0!3m2!1sen!2sus!4v1529884228017" className='bn w-100 vh-25 vh-50-ns' allowFullScreen></iframe>
			  				</div>
			  			</div>
			  		</div>
				</div>
			  </Layout>
		)
	}
}

export default ContactPage
