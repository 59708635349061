import fetch from 'isomorphic-fetch'

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

class api {
  static headers() {
    return {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'dataType': 'json',
    }
  }

  static host() {
    return '/';
  }

  static contact(params) {
    const route = 'api/contact';
    const url = `${api.host()}${route}`;
    return this.xhr(url, params, 'POST');
  }

  static post(endpoint, params) {
    const url = `${api.host()}${endpoint}`;
    return this.xhr(url, params, 'POST');
  }

  static xhr(url, params, verb, tag) {
    let options = verb === 'GET' ? { method: verb } : Object.assign({ method: verb }, params ? { body: JSON.stringify(params) } : null );
    options.headers = api.headers();
    // options.credentials = 'include';
    return fetch(url, options)
      // .then(handleErrors)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        // debugger;
        // throw(error);
        // console.error(error);
        return error;
      });
  }

}

export default api